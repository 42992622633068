import Aviator from 'aviator';
import UIStore from 'src/stores/alt/stores/UIStore';
import UIActions from 'src/stores/alt/actions/UIActions';
import UserActions from 'src/stores/alt/actions/UserActions';
import ElementActions from 'src/stores/alt/actions/ElementActions';
import ElementStore from 'src/stores/alt/stores/ElementStore';
import UserStore from 'src/stores/alt/stores/UserStore';
import DetailActions from 'src/stores/alt/actions/DetailActions';
import { elementNames } from 'src/apps/generic/Utils';
import CollectionStore from '../stores/alt/stores/CollectionStore';

const collectionShow = (e) => {
  UIActions.showElements.defer();
  UserActions.fetchCurrentUser();
  const { profile } = UserStore.getState();
  if (!profile) {
    UserActions.fetchProfile();
  }
  const uiState = UIStore.getState();
  const { currentSearchSelection } = uiState;
  const id = e.params?.collectionID;

  if (currentSearchSelection) {
    UIActions.selectCollectionWithoutUpdating({ id });
    ElementActions.fetchBasedOnSearchSelectionAndCollection({
      selection: currentSearchSelection,
      collectionId: id
    });
  } else {
    const collection = CollectionStore.findCollectionById(id);
    UIActions.selectCollection(collection);
  }

  [
    'sample',
    'reaction',
    'wellplate',
    'screen',
    'research_plan'
  ].forEach((type) => {
    UIActions.uncheckAllElements.defer({ type, range: 'all' });
  });
  elementNames(false).forEach((klass) => { UIActions.uncheckAllElements({ type: klass, range: 'all' }); });
};

const collectionShowCollectionManagement = () => {
  UIActions.showCollectionManagement();
};


const reportShowReport = () => {
  ElementActions.showReportContainer();
};

const predictionShowFwdRxn = () => {
  ElementActions.showPredictionContainer();
};

const sampleShowOrNew = (e) => {
  const { sampleID, collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState();
  const index = selecteds.findIndex((el) => el.type === 'sample' && el.id === sampleID);

  if (sampleID === 'new') {
    ElementActions.generateEmptySample(collectionID);
  } else if (sampleID === 'copy') {
    ElementActions.copySampleFromClipboard.defer(collectionID);
  } else if (index < 0) {
    ElementActions.fetchSampleById(sampleID);
  } else if (index !== activeKey) {
    DetailActions.select(index);
  }
  // UIActions.selectTab(1);
};

const cellLineShowOrNew = (e) => {
  if(e.params.new_cellLine||(e.params.new_cellLine===undefined&&e.params.cell_lineID==="new")){
     ElementActions.generateEmptyCellLine(e.params.collectionID,e.params.cell_line_template);
  }else{
    if(e.params.cellLineID){
     e.params.cellLineId=e.params.cellLineID
    }
     ElementActions.tryFetchCellLineElById.defer(e.params.cellLineId);
  }
}

const reactionShow = (e) => {
  const { reactionID, collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState();
  const index = selecteds.findIndex((el) => el.type === 'reaction' && el.id === reactionID);
  // UIActions.selectTab(2);
  if (reactionID === 'new') {
    ElementActions.generateEmptyReaction(collectionID);
  } else if (reactionID === 'copy') {
    //ElementActions.copyReactionFromClipboard(collectionID);
  } else if (index < 0) {
    ElementActions.fetchReactionById(reactionID);
  } else if (index !== activeKey) {
    DetailActions.select(index);
  }
};

const reactionShowSample = (e) => {
  const { reactionID, sampleID } = e.params;
  ElementActions.editReactionSample(reactionID, sampleID);
};

const wellplateShowOrNew = (e) => {
  const { wellplateID, collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState();
  const index = selecteds.findIndex((el) => el.type === 'wellplate' && el.id === wellplateID);

  if (wellplateID === 'new') {
    ElementActions.generateEmptyWellplate(collectionID);
  } else if (wellplateID === 'template') {
    ElementActions.generateWellplateFromClipboard.defer(collectionID);
  } else if (index < 0) {
    ElementActions.fetchWellplateById(wellplateID);
  } else if (index !== activeKey) {
    DetailActions.select(index);
  }
};

const wellplateShowSample = (e) => {
  const { wellplateID, sampleID } = e.params;
  ElementActions.editWellplateSample(wellplateID, sampleID);
};

const screenShowOrNew = (e) => {
  const { screenID, collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState();
  const index = selecteds.findIndex((el) => el.type === 'screen' && el.id === screenID);

  if (screenID === 'new') {
    ElementActions.generateEmptyScreen(collectionID);
  } else if (screenID === 'template') {
    ElementActions.generateScreenFromClipboard.defer(collectionID);
  } else if (index < 0) {
    ElementActions.fetchScreenById(screenID);
  } else if (index !== activeKey) {
    DetailActions.select(index);
  }
};

const devicesAnalysisCreate = (e) => {
  const { deviceId, analysisType } = e.params;
  ElementActions.createDeviceAnalysis(deviceId, analysisType);
};

const devicesAnalysisShow = (e) => {
  const { analysisId } = e.params;
  ElementActions.fetchDeviceAnalysisById(analysisId);
};

const deviceShow = (e) => {
  const { deviceId } = e.params;
  ElementActions.fetchDeviceById(deviceId);
};

const deviceShowDeviceManagement = () => {
  UIActions.showDeviceManagement();
};

const researchPlanShowOrNew = (e) => {
  const { research_planID, collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState();
  const index = selecteds.findIndex(el => el.type === 'research_plan' && el.id === research_planID);

  if (research_planID === 'new') {
    ElementActions.generateEmptyResearchPlan(collectionID);
  } else if (research_planID === 'copy') {
    //
  } else if (index < 0) {
    ElementActions.fetchResearchPlanById(research_planID);
  } else if (index !== activeKey) {
    DetailActions.select(index);
  }
};

const metadataShowOrNew = (e) => {
  const { collectionID } = e.params;
  const { selecteds, activeKey } = ElementStore.getState()

  // check if the metadata detail tab is alredy open
  const index = selecteds.findIndex(el => el.collection_id == collectionID)
  if (index < 0) {
    // not found, fetch the metadata from the server
    ElementActions.fetchMetadata(collectionID);
  } else if (index != activeKey) {
    // not active, activate tab
    DetailActions.select(index)
  }
};

const genericElShowOrNew = (e, type) => {
  const { collectionID } = e.params;
  let itype = '';
  if (typeof type === 'undefined' || typeof type === 'object' || type == null || type == '') {
    const keystr = e.params && Object.keys(e.params).filter(k => k != 'collectionID' && k.includes('ID'));
    itype = keystr && keystr[0] && keystr[0].slice(0,-2);
  } else {
    itype = type;
  }

  const genericElID = e.params[`${itype}ID`];
  if (genericElID === 'new') {
    ElementActions.generateEmptyGenericEl(collectionID, itype);
  } else if (genericElID === 'copy') {
    //
  } else {
    ElementActions.fetchGenericElById(genericElID, itype);
  }
};

const elementShowOrNew = (e) => {
  const type = e.type;
  switch(type) {
    case 'sample':
      sampleShowOrNew(e);
      break;
    case 'reaction':
      reactionShow(e);
      break;
    case 'wellplate':
      wellplateShowOrNew(e);
      break;
    case 'screen':
      screenShowOrNew(e);
      break;
    case 'research_plan':
      researchPlanShowOrNew(e);
      break;
    case 'metadata':
      metadataShowOrNew(e);
      break;
    case 'cell_line':
      cellLineShowOrNew(e);
      break;
    default:
      if (e && e.klassType == 'GenericEl') {
        genericElShowOrNew(e, type);
        break;
      }
      return null;
  }
  return null;
};


const buildPathForElement = (element) => {
  if (!element) { return ''; }
  const { id, isNew, type } = element;
  if (!type) { return ''; }
  if (!isNew && !id) { return `/${type}`; }
  return `/${type}/${isNew ? 'new' : id}`;
};

const buildPathForCollection = (collection) => {
  const { id, is_locked, label } = collection || {};
  if (is_locked && label === 'All') {
    return '/collection/all';
  }
  if (id) {
    return `/collection/${id}`;
  }
  return '/collection/none';
};

const buildPathForCollectionAndElement = (collection, element) => `${buildPathForCollection(collection)}${buildPathForElement(element)}`;

const AviatorNavigation = (args) => {
  const { collection, element, silent } = args;
  const { currentCollection } = UIStore.getState();
  const { currentElement } = ElementStore.getState();
  Aviator.navigate(
    buildPathForCollectionAndElement(collection || currentCollection, element || currentElement),
    { silent }
  );
};

const UrlSilentNavigation = (element) => {
  AviatorNavigation({ element, silent: true });
};

export {
  collectionShow,
  collectionShowCollectionManagement,
  reportShowReport,
  sampleShowOrNew,
  reactionShow,
  reactionShowSample,
  wellplateShowOrNew,
  wellplateShowSample,
  screenShowOrNew,
  devicesAnalysisCreate,
  devicesAnalysisShow,
  deviceShow,
  deviceShowDeviceManagement,
  researchPlanShowOrNew,
  metadataShowOrNew,
  elementShowOrNew,
  predictionShowFwdRxn,
  genericElShowOrNew,
  cellLineShowOrNew,
  buildPathForCollectionAndElement,
  AviatorNavigation,
  UrlSilentNavigation,
};
